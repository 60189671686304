html,
body,
#root,
#root > div {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 12px;
  letter-spacing: 0.05em;
}

@font-face {
  font-family: 'Sharp Sans';
  src: local('Sharp Sans'), url(./fonts/mer_typeface_sharpsans_medium-webfont.woff) format('woff');
}
